@import "../../../scss/tipperUiSection/tipperVar.scss";

.thankyouWrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  padding: 0px 32px;
  @media (max-width: 767px) {
    padding: 0px 20px;
  }
}
.thankyouHeader {
  padding-top: 28px;
}
.thankyouContent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  gap: 40px;

  .thankyouLeft {
    h3 {
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      color: #05111c;
      margin-bottom: 24px;
      @media (max-width: 767px) {
        font-size: 26px;
        margin-top: 40px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #3d4051;
      width: 84%;
      @media (max-width: 767px) {
        width: 100%;
      }
      span {
        color: #2549e8;
        cursor: pointer;
      }
    }
  }
  .btn {
    color: #fff;
    border: 1px solid #fff;
    outline: none;
    box-shadow: none;
    background-color: #2549e8;
    border-radius: 8px;
    padding: 8px 16px;
    margin-top: 40px;
    font-size: 14px;
    font-weight: 700;
    @media (max-width: 767px) {
      margin-top: 10px;
    }
  }
  .btn:hover {
    border: 1px solid #2549e8;
    color: #2549e8;
    background-color: #fff;
  }
}

.contentContainer {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 60px;
  }
}
