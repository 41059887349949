@import "../../../scss/tipperUiSection/tipperVar.scss";

// .footerLeft {
//   display: flex;
//   flex-direction: column;
//   gap: 15px;
//   p {
//     margin: 0px;
//   }
//   .footerLinks {
//     display: flex;
//     gap: 15px;
//     align-items: center;
//   }
// }

// .footerRight {
//   display: flex;
//   justify-content: center;
//   gap: 60px;
//   align-items: center;
//   @media (max-width: 767px) {
//     text-align: center;
//     display: block;
//   }
//   @media (max-width: 992px) {
//     gap: 54px;
//   }
//   @media (max-width: 767px) {
//     text-align: center;
//     display: block;
//   }
//   h6 {
//     font-weight: 700;
//     font-size: 18px;
//     line-height: 24px;
//     margin-bottom: 20px;
//     @media (max-width: 992px) {
//       font-size: 14px;
//     }
//     @media (max-width: 564px) {
//       font-size: 18px;
//     }
//   }
//   .footertext {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 60px;
//     p {
//       color: black;
//       font-weight: 400;
//     }
//     @media (max-width: 767px) {
//       gap: 25px;
//     }
//     @media (max-width: 424px) {
//       gap: 4px;
//     }
//     @media (max-width: 375px) {
//       p {
//         font-size: 12px !important;
//       }
//       gap: 8px;
//     }
//     .ticket {
//       text-decoration: underline;
//       display: flex;
//       align-items: center;
//       gap: 10px;
//       white-space: nowrap;
//       @media (max-width: 767px) {
//         text-align: center;
//         display: block;
//       }
//     }
//     p {
//       display: flex;
//       align-items: center;
//       gap: 5px;
//       white-space: nowrap;
//       @media (max-width: 767px) {
//         text-align: center;
//         // display: block;
//       }
//     }
//   }
// }

// .bottomFooter {
//   display: flex;
//   align-items: center;
//   justify-content: space-evenly;
//   background-color: $primary;
//   height: 52px;
//   width: 100%;
//   margin-top: 10px;
//   @media (max-width: 767px) {
//     display: block;
//     height: 78px;
//     text-align: center;
//   }
//   p {
//     color: white;
//     margin: 0px;
//     font-size: 14px;
//     font-weight: 500;
//     @media (max-width: 767px) {
//       padding: 13px 4px 0px 4px;
//     }
//     @media (max-width: 374px) {
//       padding: 10px 4px 0px 4px;
//     }
//   }
//   .bottomRight {
//     display: flex;
//     align-items: center;
//     gap: 50px;
//     @media (max-width: 767px) {
//       // display: block;
//       gap: 30px;
//       justify-content: center;
//     }
//     @media (max-width: 375px) {
//       gap: 15px;
//       justify-content: space-between;
//     }
//     p {
//       font-size: 14px;
//       font-weight: 500;
//       margin: 0px;
//       @media (max-width: 375px) {
//         font-size: 12px !important;
//       }
//     }
//   }
// }

.footer {
  .footerWrapper {
    width: 430px;
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;
    @media (max-width: 475px) {
      padding: 12px 5px !important;
      width: calc(100vw - 20px);
    }
  }
  .getHelp {
    color: $primary;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .sayHi {
    color: $primary;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
