@import "../../../scss/tipperUiSection/tipperVar.scss";

.confirmationModal {
  :global(.modal-body) {
    padding: 2rem 0.5rem;
  }
  .modalContent {
    color: #222;
    display: flex;
    // gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // .btnRight {
    //   background: #00a3ff;
    //   border-color: #00a3ff;
    // }
    // .btnLeft {
    //   color: #00a3ff;
    //   border-color: #00a3ff;
    //   &:hover {
    //     background-color: transparent;
    //   }
    // }
  }
  .modalTitle {
    text-align: center;

    h4 {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%; /* 21px */
      margin-bottom: 0;
      color: $secondary;
    }
    h2 {
      margin-top: 1rem;
      text-align: center;
      // font-family: Graphik;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: $primary;
      margin-top: 10px;
    }
  }
}

.confirmationModal {
  :global(.modal-dialog) {
    width: 340px;
    @media only screen and (max-width: 480px) {
      width: auto;
    }
  }
}

.modalContent {
  :global(.modal-footer) {
    justify-content: center;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    button {
      padding: 0 25px;
    }
  }
}
// .FooterModal {
//   border-radius: 6px;
//   background: $black !important;
//   border: 1px solid $black !important;
//   // margin-left: auto;
//   height: 40px;
//   width: auto;
//   padding: 0 25px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
