@import "../tipperUiSection/tipperVar.scss";
img {
  max-width: 100%;
}
body {
  margin: 0;
  font-family: "Graphik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $lightBlue;
}

h2 {
  color: $black;
  font-weight: $fontExtraBold;
  line-height: 1.5;
  font-size: $fontXL;
  text-align: left;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
  color: $secondary;
}
h5 {
  font-size: $fontLG;
  color: $secondary;
}
h6 {
  font-size: $fontMD;
}
p {
  color: $secondary;
  // font-size: $fontSM;
  font-weight: $fontNormal;
  line-height: 1.5;
}
li {
  font-size: $fontSM;
}

.tippingEnabled {
  background-color: $white;
  color: $primary !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  min-height: 80px;
  max-width: 80%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  margin-top: 8px;
}
