@import "../../../scss/tipperUiSection/tipperVar.scss";

.thankyou_container {
  // height: calc(100vh - 163px);
  min-height: 80vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: white;
  width: max-content;
  min-height: 520px;
  margin: auto;
  margin: 20px auto 0px;
  border-radius: 20px;
  padding: 20px;

  @media (max-width: 576px) {
    width: calc(100vw - 20px);
  }
  @media (max-width: 424px) {
    padding: 6px;
  }
  .thankyou_wrapper {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;

    @media (max-width: 767px) {
      width: 390px;
      min-height: 150px;
    }
    @media (max-width: 424px) {
      width: 344px;
    }
    @media (max-width: 374px) {
      width: 300px;
    }
    .img {
      margin: 0px auto 10px;
      border-radius: 10px;
      object-fit: contain;
    }
    .text {
      h5 {
        font-weight: 600;
        font-size: 24px;
        color: $black;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: $black;
        text-align: center;
      }
    }
    .thankyou_card {
      min-height: max-content;
      width: 100%;
      text-align: center;
      background-color: $white;
      margin: 0px auto;
      border-radius: 6px;
      padding: 18px;
      img {
        object-fit: contain;
        display: block;
        margin: auto;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: $primary;
      }
    }
  }
}

.circle {
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Should be equal to width for a perfect circle */
  border-radius: 50%; /* Makes the div circular */
  border: 6px solid $lightBlue; /* Border style */
  overflow: hidden; /* Ensures the image stays within the circle */
  position: relative; /* For positioning the image */
}

.circle img {
  width: 100%; /* Ensures the image fills the circle */
  height: 100%; /* Ensures the image fills the circle */
  object-fit: contain; /* Ensures the image covers the entire area */
  position: absolute; /* Position relative to the circle div */
  top: 0; /* Centering the image vertically */
  left: 0; /* Centering the image horizontally */
}

.links {
  width: 100%;
  border-radius: 8px;
  border: 2px solid black;
  padding: 6px;
  color: black;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
