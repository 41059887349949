.navWrapper {
  // padding: 22px;
  p {
    font-size: 14px;
  }
  .topTipper {
    color: #111111;
    font-weight: 700;
  }
  .stripe {
    color: #6772E5;
    font-weight: 1000;
    font-size: 16px;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
}
