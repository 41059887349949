.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
}
.p-LinkAutofillPrompt {
  background-color: aqua;
  /* display: none !important; */
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* css used for reactSkeleton component */
.suggestedAmount {
  @media (max-width: 767px) {
    width: 350px !important;
    margin: auto !important;
  }
  @media (max-width: 375px) {
    width: 300px !important;
    /* margin: auto !important; */
  }
}
.suggesstCol {
  @media (max-width: 567px) {
    left: 80px !important;
  }
}
.suggesstOne {
  @media (max-width: 567px) {
    left: 80px !important;
  }
}
.inputBox {
  @media (max-width: 767px) {
    width: 300px !important;
  }
}
.desc {
  @media (max-width: 767px) {
    width: 300px !important;
  }
}
.suggest {
  @media (max-width: 524px) {
    width: max-content;
    height: max-content;
  }
  @media (max-width: 424px) {
    width: 312px !important;
    height: max-content;
  }
  @media (max-width: 374px) {
    width: 254px !important;
    height: max-content;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.modal-body {
  min-height: 312px !important;
}
.imageBox {
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 8px;
  img {
    object-fit: contain !important;
  }
}
.input-group {
  position: relative;
}
.eye_icon {
  position: absolute;
  right: 0px;
  top: 25%;
  padding-right: 10px;
  z-index: 99;
  margin-left: 20px;
  /* padding-left: 10px; */
}
