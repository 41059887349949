@import "../../../scss/tipperUiSection/tipperVar.scss";

.guestyWrapper {
  background-color: $white;
  padding-left: 135px;
  position: relative;
  @include max($xl2) {
    padding-left: 70px;
  }
  @include max($dxl) {
    padding: 0 30px 30px;
  }
  @include max($sm) {
    padding: 0 15px 30px;
  }

  .guestyRight {
    @include max($dxl) {
      display: flex;
      flex-direction: column-reverse;
    }
    .formContent {
      .header {
        width: 190px;
        padding-top: 60px;
      }
      .leftContent {
        position: relative;
        z-index: 9;
        padding-bottom: 100px;

        .guesty_partner_content {
          .guestyHeading {
            margin-top: 78px;

            h3 {
              font-size: 36px;
              font-weight: 700;
              line-height: 39.6px;
              color: $black;
              margin-bottom: 14px;
            }
            p {
              font-size: 20px;
              font-weight: 400;
              line-height: 30px;
              color: var(--Grey, #6a6d7c);
              width: 60%;
              margin-bottom: 34px;
              @include max($dxl) {
                width: 100%;
              }
            }
          }
          .guesty_partner_signup {
            width: 50%;
            .url_input {
              display: flex;
              border: 0.5px solid #aeb1c2;
              flex-direction: row;
              align-items: center;
              border-radius: 6px;
              padding-left: 30px;
              color: #aeb1c2;
            }
            .custom_input {
              border: none;
              width: 40%;
              padding-left: 0px !important;
              &:focus-visible {
                border: none !important;
                outline: none !important;
              }
              @media (max-width: 1366px) {
                width: 30%;
              }
            }

            @media (max-width: 1440px) {
              width: 45%;
            }
            @media (max-width: 1366px) {
              width: 40%;
            }
            @include max($dxl) {
              width: 100%;
            }
            label {
              font-size: 16px;
              font-weight: 500;
              line-height: 17.6px;
              color: $black;
              margin-bottom: 12px;
            }

            input {
              padding: 18px 30px !important;
              border: 0.5px solid #aeb1c2;
              width: 100%;
              border-radius: 6px;
              &::placeholder {
                font-size: 16px;
                font-weight: 400;
                line-height: 17.6px;
                color: #aeb1c2;
              }
              &:focus {
                box-shadow: none;
              }
            }
            .submit_btn {
              height: 60px;
              margin-top: 60px;
            }
          }
        }
      }
    }
    .rightContent {
      @include max($dxl) {
        background-color: $black;
      }
      @include max($sm) {
        padding: 0px 10px;
      }
      .mob_view {
        display: none;
        @include max($dxl) {
          display: block;
        }
        .guesty_right_img {
          display: flex;
          justify-content: end;
          margin-top: 30px;
          @include max($sm) {
            justify-content: center;
          }

          img {
            width: 40%;
            padding-right: 30px;
            @include max($sm) {
              padding-right: 0px;
              width: 80%;
            }
          }
        }
        .partnerStar {
          text-align: center;
          margin-top: -33px;
          margin-left: 150px;
          @include max($sm) {
            margin-left: 0px;
            margin-top: 15px;
          }
        }
        .guesty_partner_rightContent {
          color: #fff;
          display: flex;
          justify-content: start;
          padding-top: 15px;
          line-height: 48px;
          padding-left: 30px;
          padding-bottom: 14px;
          @include max($sm) {
            padding-left: 0px;
          }

          .content {
            .guesty_points {
              margin-top: 52px;
            }
            h5 {
              font-size: 24px;
              font-weight: 500;
              line-height: 39.6px;
              color: $white;
            }
            .partner_content {
              display: flex;
              align-items: baseline;
              gap: 5px;
              .guesty {
                color: #3b9eff;
                font-weight: bold;
                font-size: 46px;
                margin-right: 5px;
                @include max($sm) {
                  font-size: 34px;
                }
              }
              h5 {
                font-weight: bold;
                font-size: 22px;
                margin-bottom: 0 !important;
                line-height: 32px;
                @include max($sm) {
                  font-size: 18px;
                }
              }
              .toptipper {
                color: $white;
                margin: 0 0 0 5px;
                font-size: 46px;
                @include max($sm) {
                  font-size: 34px;
                }
              }

              .first {
                font-size: 24px;
                font-weight: 400;
                line-height: 26.4px;
                color: $white;
                @include max($sm) {
                  font-size: 18px;
                }
              }
              p {
                font-size: 24px;
                font-weight: 400;
                line-height: 26.4px;
                color: $white;
                @include max($sm) {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
      .right_side {
        position: absolute;
        inset: 0;
        left: unset;
        z-index: 1;
        display: block;
        @include max($dxl) {
          display: none;
        }
        &::before {
          content: "";
          background: url("../../../assests/tipperUiSection/partner.png")
            no-repeat;
          position: absolute;
          top: 16%;
          background-size: 100%;
          width: 320px;
          height: 253px;
          right: 33px;
        }
        &::after {
          content: "";
          background: url("../../../assests/tipperUiSection/content.png")
            no-repeat;
          position: absolute;
          top: 50%;
          background-size: 100%;
          width: 432px;
          height: 448px;
          right: 113px;
        }
        figure {
          margin: 0;
          height: 100%;
          &::after {
            content: "";
            background: url("../../../assests/tipperUiSection/partner_star.png")
              no-repeat;
            position: absolute;
            top: 35%;
            background-size: 100%;
            width: 52px;
            height: 52px;
            right: 305px;
          }
          img {
            max-width: 945px;
            height: 100%;
            @media (max-width: 1366px) {
              max-width: 900px;
            }
          }
        }
      }
    }
  }
}
