@import "../../scss/tipperUiSection/tipperVar.scss";

.custom_btn {
  background-color: $primary !important;
  min-width: 150px !important;
  border-radius: 6px;
  // border: 1px solid $black !important;
  // margin-left: auto;
  height: 40px;
  width: 100%;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  // &:hover {
  //   background-color: $lightBlue !important;
  //   box-shadow: none !important;
  //   color: $primary;
  // }
  // &:focus {
  //   outline: none !important;
  //   border-color: transparent;
  //   box-shadow: none !important;
  // }
}
