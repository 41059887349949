@import "../../../scss/tipperUiSection/tipperVar.scss";

.formContainer {
  min-height: 643px;
  max-height: 868px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: white;
  width: max-content;
  margin: auto;
  padding: 0px 30px;
  border-radius: 20px;

  @media (max-width: 576px) {
    width: calc(100vw - 20px);
  }
  @media (max-width: 375px) {
    min-height: 575px;
    margin-top: 20px;
  }
  .tipperBottom {
    width: 417px;

    @media (max-width: 564px) {
      width: 300px;
      min-height: max-content;
    }

    .not_foundImg {
      display: flex;
      justify-content: center;
      img {
        padding-top: 66px;
      }
    }
    h3 {
      margin-top: 55px;
      font-size: 26px;
      text-align: center;
      font-weight: 600;
      @media (max-width: 567px) {
        font-size: 22px;
      }
    }
    p {
      text-align: center;
      margin-top: 24px;
      font-size: 16px;
      font-weight: 400;
      color: #3d4051;
      @media (max-width: 567px) {
        font-size: 14px;
      }
    }
  }
}
