@import "../../scss/tipperUiSection/tipperVar.scss";

.formContainer {
  // height: calc(100vh - 163px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 767px) {
    // height: calc(100vh - 205px);
  }
  @media (max-width: 424px) {
    padding: 10px;
  }
}

.tipperContainer {
  .tipperBottom {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    margin-bottom: 20px;
    width: 417px;
    min-height: max-content;
    background-color: $white;
    padding-bottom: 25px;
    padding-top: 40px;
    border-radius: 10px;
    position: relative;
    span {
      display: inline-block !important;
      margin: auto !important;
    }
    @media (max-width: 767px) {
      width: 400px;
      height: 500px;
    }
    @media (max-width: 424px) {
      width: 350px;
      height: 500px;
    }
    img {
      width: 70px;
      height: 70px;
      margin: 30px auto 10px;
      border-radius: 10px;
    }

    .tipperRightForm {
      padding: 0px 30px;
      text-align: left;
      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
      .search {
        position: relative;
        display: flex;
        // margin-bottom: 10px;
        @media only screen and (max-width: 767px) {
          flex-direction: column;
          gap: 16px;
          button {
            margin-left: 0 !important;
          }
        }
        @media only screen and (max-width: 480px) {
          width: 100%;
        }
      }
    }
  }
}
