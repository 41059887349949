@import "../../../scss/tipperUiSection/tipperVar.scss";

.orderContainer {
  // height: calc(100vh - 163px);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 728px) {
    padding: 10px 50px;
  }
  @media (max-width: 564px) {
    padding: 10px 5px;
    width: calc(100vw - 10px);
    // height: calc(100vh - 80px);
  }
  .tipperContainer {
    padding: 20px;
    background-color: $white;
    border-radius: 20px;
    max-width: max-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (max-width: 524px) {
      width: 100%;
      padding: 20px 0px;
      gap: 5px;
    }
    .tipperTop {
      // flex-basis: 20%;
      display: flex;
      background-color: $white;
      border-radius: 10px;
      justify-content: flex-start;
    }
    .tipperImg {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      @media (max-width: 567px) {
        padding-left: 10px;
      }

      img {
        border-radius: 10px;
        object-fit: contain;
      }
    }
    .tipperBottom {
      background-color: $white;
      border-radius: 10px;
      position: relative;
      span {
        justify-content: center !important;
        margin: auto !important;
        text-align: center;
      }
      @media (max-width: 728px) {
        padding: 20px;
      }
      @media (max-width: 424px) {
        padding: 11px;
      }
      p {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #0a0e18;
      }

      h5 {
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        color: $black;
        word-wrap: break-word;
        padding: 0px 3px;
      }
      .text {
        text-align: center;
        color: $primary;
        text-decoration: underline;
        font-size: 14px;
        font-weight: 500;
      }

      .suggestedAmount {
        max-width: 100%;
        min-height: 99px;
        background-color: $lightBlue;
        display: flex;
        flex-direction: column;
        margin: auto;
        border-radius: 6px;
        margin-bottom: 15px;
        padding: 10px;
        justify-content: center;
        @media (max-width: 767px) {
          width: 100%;
          // padding: 5px;
        }
        .skel {
          display: flex;
          justify-content: space-evenly;
          gap: 50px;
          position: absolute;
          top: 20px;
          left: 50px;
          @media (max-width: 424px) {
            left: 7%;
          }
          @media (max-width: 374px) {
            left: 1%;
          }
        }

        h6 {
          text-align: center;
          font-size: 14px;
          font-weight: 500;
        }
        .suggestCards {
          display: flex;
          gap: 10px;
          align-items: flex-start;
          justify-content: space-evenly;
          flex-wrap: wrap;
          @media (max-width: 374px) {
            justify-content: space-evenly;
            align-items: center;
          }
          cursor: pointer;
          .chargeAmount {
            // padding-top: 15px;
            @media (max-width: 576px) {
              padding-top: 0px;
            }
            p {
              font-size: 12px;
              font-weight: 400;
              color: $secondary;
              margin: 0px;
            }
            span {
              display: inline-block;
              width: 100%;
              text-align: center !important;
              font-size: 16px;
              font-weight: 700;
              margin-top: 10px !important;
            }
            .differColor {
              color: $primary;
              font-size: 20px;
              font-weight: 500;
              margin: 0px;
            }
            .end {
              text-align: end;
              display: inline-block;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.circle {
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Should be equal to width for a perfect circle */
  border-radius: 50%; /* Makes the div circular */
  border: 6px solid $lightBlue; /* Border style */
  overflow: hidden; /* Ensures the image stays within the circle */
  position: relative; /* For positioning the image */
}

.circle img {
  width: 100%; /* Ensures the image fills the circle */
  height: 100%; /* Ensures the image fills the circle */
  object-fit: contain; /* Ensures the image covers the entire area */
  position: absolute; /* Position relative to the circle div */
  top: 0; /* Centering the image vertically */
  left: 0; /* Centering the image horizontally */
}
