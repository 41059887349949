@import "../scss/tipperUiSection/tipperVar.scss";

.stripe_container {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  // border: 1px solid red;
  // width: 480px;
  margin: auto;
  //   gap: 10px;
  text-align: left !important;
  // padding: 10px 30px 30px 30px;
  @media (max-width: 728px) {
    padding: 0px;
  }
  .stripe_wrapper {
    label {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      color: $secondary;
      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .cardno {
      border: 0.5px solid $lightGrey;
      padding: 13px;
      border-radius: 6px;
      width: 100%;
      // color: red;
      margin-bottom: 10px;
      background-color: white;

      input {
        &::placeholder {
          color: $lightGrey;
          font-size: 14px;
          font-weight: 400;
        }
      }

      //   height: 50px;
    }
    .cardInputContainer {
      position: relative !important;
      background-color: red;
      .cardIcons {
        position: absolute;
        top: 4px;
        right: 7px;
      }
    }
    .exp {
      border: 0.5px solid $lightGrey;
      padding: 13px;
      border-radius: 6px;
      width: 100%;
      margin-bottom: 10px;
      background-color: white;
      &::placeholder {
        color: $lightGrey;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .country {
      width: 100%;
      border-radius: 6px;
      padding: 13px;
      background-color: white;
      border: 0.5px solid $lightGrey;
      margin-bottom: 10px;
      color: $lightGrey;
      font-size: 14px;
      font-weight: 400;
      outline: none;
    }
    .email {
      width: 100%;
      padding: 10px;
      border-radius: 6px;
      border: 0.5px solid $lightGrey;
      outline: none;
      margin-bottom: 20px;
      &::placeholder {
        color: $lightGrey;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
  }
}
