@import "../../scss/tipperUiSection/tipperVar.scss";
.formContainer {
  // min-height: max-content;
  height: 84vh;
  min-height: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: white;
  width: max-content;
  margin: auto;
  // margin: 20px auto 0px;
  border-radius: 20px;

  @media (max-width: 576px) {
    width: calc(100vw - 20px);
  }

  .tipperContainer {
    padding: 10px;
    .tipperBottom {
      width: 417px;

      @media (max-width: 767px) {
        min-width: 385px;
        min-height: max-content;
      }
      @media (max-width: 564px) {
        min-width: 100%;
        min-height: max-content;
      }
      @media (max-width: 424px) {
        width: 350px;
      }
      @media (max-width: 375px) {
        width: 310px;
        min-height: max-content;
      }

      .imgSkel {
        width: 50px;
        height: 50px;
        background-color: #0a0e18;
        border-radius: 10px;
      }

      p {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: $secondary;
        word-wrap: break-word;
        padding: 0px 3px;
      }

      h5 {
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        color: $black;
        word-wrap: break-word;
        padding: 0px 3px;
        // margin-bottom: 20px;
      }
      .text {
        text-align: center;
        color: $secondary;
        font-size: 14px;
        font-weight: 400;
      }
      .tipperRightForm {
        padding: 0px 18px;
        text-align: left;
        min-height: 90px;
        label {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 5px;
          color: $secondary;
        }
        .search {
          position: relative;
          display: flex;
          border-radius: 6px;
          border: 0.5px solid $lightGrey;
          width: 100%;
          // margin-bottom: 10px;
          @media only screen and (max-width: 767px) {
            flex-direction: column;
            gap: 16px;
            button {
              margin-left: 0 !important;
            }
          }
          @media only screen and (max-width: 480px) {
            width: 100%;
          }
          svg {
            position: absolute;
            top: 18px;
            left: 22px;
            @media only screen and (max-width: 767px) {
              top: 28px;
            }
          }
          input {
            // padding-left: 58px;
            height: 50px;
            text-align: center;
            border: none;
            background: $white;
            width: 50%;
            margin: auto;
            padding-bottom: 3px;
            &::placeholder {
              color: $lightGrey;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: $fontNormal;
              line-height: 50px; /* 18px */
            }
            &:focus-visible {
              outline: none;
            }
            @media only screen and (max-width: 564px) {
              // padding-left: 35px;
            }
          }
        }
        p {
          margin-top: 8px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 15px;
          text-align: left;
          color: $black;
          display: flex;
          gap: 10px;
        }
      }
      .suggestedAmount {
        width: 100%;
        padding: 0px 18px;
        // height: 114px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 0px auto;
        border-radius: 6px;

        h6 {
          text-align: center;
          font-size: 12px;
          font-weight: 700;
          color: $secondary;
        }
        .suggestCards {
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: 10px;
          cursor: pointer;
          .differColor {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 56px;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 700;
            color: $white;
            margin: 0px;
            background-color: $primary;
          }
          p {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 56px;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 400;
            color: $white;
            margin: 0px;
            background-color: $skyBlue;
          }
        }
      }
    }
  }
}

.error {
  text-align: end;
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: $red !important;
}
.addCateg {
  padding: 0px 20px;
  label {
    color: #6A6D7C;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
  input {
    border-radius: 8px;
    border: 1px solid #AEB1C2;
    width: 92%;
    padding: 8px;
  }
}

.circle {
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Should be equal to width for a perfect circle */
  border-radius: 50%; /* Makes the div circular */
  border: 6px solid $lightBlue; /* Border style */
  overflow: hidden; /* Ensures the image stays within the circle */
  position: relative; /* For positioning the image */
}

.circle img {
  width: 100%; /* Ensures the image fills the circle */
  height: 100%; /* Ensures the image fills the circle */
  object-fit: contain; /* Ensures the image covers the entire area */
  position: absolute; /* Position relative to the circle div */
  top: 0; /* Centering the image vertically */
  left: 0; /* Centering the image horizontally */
}

.user {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0%;
  margin: 0%;
  margin-bottom: 20px;
  span {
    display: inline-block;
    background: $lightBlue;
    border-radius: 20px;
    padding: 2px 23px;
    font-size: 12px;
    font-weight: 400;
  }
}

.email {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 0.5px solid $lightGrey;
  outline: none;
  margin-bottom: 20px;
  // margin: 0px 18px;
  &::placeholder {
    color: $lightGrey;
    font-size: 14px;
    font-weight: 400;
  }
}

